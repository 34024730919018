import * as React from 'react'
import i18next from '../i18n.js'
import { useEffect } from 'react'
import * as Styles from "../styles/form2.module.scss"

const ContactForm = () => {
  var { t } = i18next
  useEffect(() => {
    document.getElementById('sendcntctform').addEventListener('click', (event) => {
        event.preventDefault();
        const name = document.getElementById('Name').value;
        const email = document.getElementById('email').value;
        const hp = document.getElementById('hp').value;
        const message = document.getElementById('message').value;;

        console.log('FETCHING');
        fetch(`/api/submit-contact-form`, {
            "headers": {
              "Content-Type": "application/json"
            },
            "body": JSON.stringify({
                name: name,
                email: email,
                hp: hp,
                message: message
            }),
            "method": "POST",
            "mode": "cors",
            "credentials": "include"
        })
        .then(r => r.json())
        .then(data => 
        {
            let status = document.getElementById('statuscontact');
            if(data.status === 'error')
            {
                status.innerHTML = '<span class="'+Styles.fadeStatusBox+'" style="background: #f72648;">' + data.message + '</span>';
            }
            else if(data.status === 'success')
            {
                status.innerHTML = '<span class="'+Styles.fadeStatusBox+'" style="background: #18ca6b;">' + data.message + '</span>';
                document.getElementById('Name').value = '';
                document.getElementById('email').value = '';
                document.getElementById('hp').value = '';
                document.getElementById('message').value = '';
            }
            setTimeout(() => {
                status.innerHTML = '';
            }, 5000);
        })
        .catch(error => console.error('There was a problem with the fetch operation:', error));  
    });
  }, []);
  return (
        <div>
            {/* <form action="/api/submit-contact-form" method="POST" class={Styles.form} target="hiddenFrame"> */}
           {/* <form name="contact" method="POST" data-netlify="true"  target="hiddenFrame">*/}
                <fieldset>

                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="Name">{t('contactFormName')}
                            <div class={Styles.field}>
                                <input id="Name" name="name" type="text" placeholder="Vor- und Nachname" class="form-control input-md"  required="required" aria-required="true" />
                            </div>
                        </label>
                    </div>

                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="email">{t('contactFormEmail')}
                            <div class={Styles.field}>
                                <input id="email" name="email" type="text" placeholder="name@company.com" class="form-control input-md"  required="required" aria-required="true" />
                            </div>
                        </label>
                    </div>

                    <div class={Styles.hidden}>
                        <label class={Styles.label} for="email">Honey Pot
                            <div class={Styles.field}>
                                <input id="hp" name="hp" type="text" class="form-control input-md" placeholder="Nur von Robots auszufüllen" />
                            </div>
                        </label>
                    </div>

                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="message">{t('contactFormMessage')}
                            <div class={Styles.field}>
                                <textarea class="form-control" id="message" name="message"  ></textarea>
                            </div>
                        </label>
                    </div>
            <button id="sendcntctform" className={ Styles.contactBtn }>
                    Absenden
                     </button>
                </fieldset>

            {/* </form> */}
            <div className={ Styles.contactResult } id="statuscontact"></div>
        </div>

    )
}
export default ContactForm 